var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"customReportModal",attrs:{"size":"xl","id":"irReportWriterModal","modal-class":"custom-report-modal","body-class":"cpg-body","no-close-on-backdrop":"","no-close-on-esc":"","hide-footer":""},on:{"show":_vm.loadInitData}},[_c('template',{slot:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.isUpdateMode ? _vm.keyMetricReport ? 'Edit Key Metric Report' : 'Edit Custom Report' : 'New Custom Report')+" "),(_vm.keyMetricReport)?_c('span',{staticClass:"p-0 pl-1 pb-1  favorite__star__selected"},[_c('b',[_vm._v(" ★")])]):_vm._e()]),_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.closeModal($event)}}},[_vm._v("x")])]),_c('div',{staticClass:"px-2"},[_c('v-wait',{attrs:{"for":"loadingSelectedReport"}},[_c('template',{slot:"waiting"},[_c('content-placeholders',{staticClass:"pt-4",attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":15}})],1)],1),_c('custom-report',{ref:"customReport",attrs:{"showOutput":true,"selectedReport":_vm.selectedReport,"rendered-in":_vm.renderedIn}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9 col-sm-9-mv-70"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 pb-1"},[_c('b-form-group',[_c('label',{staticClass:"mb-1",attrs:{"for":"Search"}},[_c('b',[_vm._v("Report Name")]),_c('i',{staticClass:"pl-1"},[_vm._v("(required on save)")])]),_c('b-form-input',{ref:"reportName",staticClass:"form-control rounded",class:[
                        _vm.$v.reportName.$dirty && _vm.$v.reportName.$invalid
                          ? 'is-invalid'
                          : '',
                        'form-control rounded'
                      ],attrs:{"type":"text","name":"report_name","placeholder":"Enter a Report Name"},model:{value:(_vm.reportName),callback:function ($$v) {_vm.reportName=$$v},expression:"reportName"}}),(_vm.$v.reportName.$dirty && _vm.$v.reportName.$invalid)?_c('b-form-invalid-feedback',[_vm._v("report name required to save")]):_vm._e(),(_vm.showEditWarning)?_c('div',{staticClass:"invalid-feedback d-block info-msg"},[_c('span',{staticClass:"fa fa-info-circle pr-1"}),_vm._v(" changing name will save this as new report ")]):_vm._e()],1)],1)])])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-group',[_c('label',{staticClass:"mb-1",attrs:{"for":"Search"}},[_c('b',[_vm._v("Description")]),_c('i',{staticClass:"pl-1"},[_vm._v("(Optional)")])]),_c('b-form-textarea',{staticClass:"form-control rounded",attrs:{"id":"description","rows":"2","max-rows":"2"},model:{value:(_vm.reportDescription),callback:function ($$v) {_vm.reportDescription=$$v},expression:"reportDescription"}})],1)],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-group',[_c('label',{staticClass:"mb-1",attrs:{"for":"Search"}},[_c('b',[_vm._v("Group Access")]),_c('i',{staticClass:"pl-1"},[_vm._v("(Optional)")])]),_c('multiselect',{staticClass:"simple-select",attrs:{"options":_vm.userGroups,"multiple":true,"close-on-select":false,"label":"name","track-by":"id","showLabels":false,"placeholder":"","searchable":false},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var values = ref.values;
return [_vm._v(_vm._s(values.length)+" "+_vm._s(_vm._f("pluralize")(values.length,'group'))+" selected")]}},{key:"option",fn:function(group){return [_c('div',[_c('input',{staticClass:"mr-1",attrs:{"type":"checkbox"},domProps:{"checked":_vm.isGroupSelected(group.option)}}),_vm._v(" "+_vm._s(group.option.name)+" ")])]}}]),model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}})],1)],1)])])]),_c('div',{staticClass:"col-sm-9 d-flex justify-content-center mt-3 mb-3"},[(_vm.saveAsMode || !_vm.isEditMode)?_c('div',{staticClass:"px-1"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.customReportsList",modifiers:{"customReportsList":true}}],staticClass:"btn fw-btn btn-success rounded",attrs:{"type":"button"}},[_vm._v(" Load Report ")])]):_vm._e(),_c('div',{staticClass:"px-1 d-flex flex-column"},[_c('div',[_c('button',{class:['btn fw-btn btn-success rounded'],attrs:{"type":"button"},on:{"click":function($event){return _vm.saveReport()}}},[_vm._v(" Save Report ")])])]),_c('div',{staticClass:"px-1"},[_c('button',{staticClass:"btn fw-btn btn-secondary rounded",attrs:{"type":"button"},on:{"click":_vm.resetReport}},[_vm._v(" Reset Report ")])])])],2),_c('custom-reports-modal',{attrs:{"renderedIn":"Reporter","fiType":_vm.selectedReport.fi_type},on:{"reportLoaded":_vm.reportLoaded}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }