<template>
  <b-modal
    size="xl"
    id="irReportWriterModal"
    modal-class="custom-report-modal"
    ref="customReportModal"
    @show="loadInitData"
    body-class="cpg-body"
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
  >
    <template slot="modal-header">
      <h5 class="modal-title">
        {{
          isUpdateMode
            ? keyMetricReport
              ? 'Edit Key Metric Report'
              : 'Edit Custom Report'
            : 'New Custom Report'
        }}
        <span
          class="p-0 pl-1 pb-1  favorite__star__selected"
          v-if="keyMetricReport"
          ><b> &#x2605;</b>
        </span>
      </h5>

      <button type="button" class="close" @click.prevent="closeModal">x</button>
    </template>
    <div class="px-2">
      <v-wait for="loadingSelectedReport">
        <template slot="waiting">
          <content-placeholders :rounded="true" class="pt-4">
            <content-placeholders-text :lines="15"></content-placeholders-text>
          </content-placeholders>
        </template>
        <custom-report
          :showOutput="true"
          :selectedReport="selectedReport"
          :rendered-in="renderedIn"
          ref="customReport"
        ></custom-report>

        <div class="row">
          <div class="col-sm-9 col-sm-9-mv-70">
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-12 pb-1">
                    <b-form-group>
                      <label for="Search" class="mb-1">
                        <b>Report Name</b><i class="pl-1">(required on save)</i>
                      </label>
                      <b-form-input
                        type="text"
                        name="report_name"
                        class="form-control rounded"
                        placeholder="Enter a Report Name"
                        v-model="reportName"
                        ref="reportName"
                        :class="[
                          $v.reportName.$dirty && $v.reportName.$invalid
                            ? 'is-invalid'
                            : '',
                          'form-control rounded'
                        ]"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="$v.reportName.$dirty && $v.reportName.$invalid"
                        >report name required to save</b-form-invalid-feedback
                      >
                      <div
                        class="invalid-feedback d-block info-msg"
                        v-if="showEditWarning"
                      >
                        <span class="fa fa-info-circle pr-1"></span>
                        changing name will save this as new report
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <b-form-group>
                  <label for="Search" class="mb-1">
                    <b>Description</b><i class="pl-1">(Optional)</i>
                  </label>
                  <b-form-textarea
                    id="description"
                    class="form-control rounded"
                    v-model="reportDescription"
                    rows="2"
                    max-rows="2"
                  >
                  </b-form-textarea>
                </b-form-group>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <b-form-group>
                  <label for="Search" class="mb-1">
                    <b>Group Access</b><i class="pl-1">(Optional)</i>
                  </label>

                  <multiselect
                    v-model="selectedGroups"
                    :options="userGroups"
                    :multiple="true"
                    :close-on-select="false"
                    label="name"
                    track-by="id"
                    :showLabels="false"
                    placeholder
                    class="simple-select"
                    :searchable="false"
                  >
                    <template slot="selection" slot-scope="{ values }"
                      >{{ values.length }}
                      {{ values.length | pluralize('group') }}
                      selected</template
                    >
                    <template slot="option" slot-scope="group">
                      <div>
                        <input
                          type="checkbox"
                          :checked="isGroupSelected(group.option)"
                          class="mr-1"
                        />
                        {{ group.option.name }}
                      </div>
                    </template>
                  </multiselect>
                </b-form-group>
              </div>
            </div>
            <!-- <div class="row justify-content-center">
              <div :class="['col-sm-6']">
                <span
                  class="p-0 pr-1 pb-1  favorite__star__selected"
                  v-if="keyMetricReport"
                  ><b> &#x2605;</b>
                </span>
                <h6 class="mb-1 pr-2 d-inline-block pt-1">
                  <b>Key Metric Report</b>
                </h6>
                <c-switch
                  type="text"
                  variant="success"
                  on="Yes"
                  off="No"
                  :pill="true"
                  size="sm"
                  :checked="keyMetricReport"
                  disabled="true"
                  class="mb-0 mt-1"
                ></c-switch>
              </div>
            </div>-->
          </div>
        </div>

        <div class="col-sm-9 d-flex justify-content-center mt-3 mb-3">
          <div class="px-1" v-if="saveAsMode || !isEditMode">
            <button
              type="button"
              class="btn fw-btn btn-success rounded"
              v-b-modal.customReportsList
            >
              Load Report
            </button>
          </div>
          <div class="px-1 d-flex flex-column">
            <div>
              <button
                type="button"
                :class="['btn fw-btn btn-success rounded']"
                @click="saveReport()"
              >
                Save Report
              </button>
            </div>
          </div>
          <div class="px-1">
            <button
              type="button"
              class="btn fw-btn btn-secondary rounded"
              @click="resetReport"
            >
              Reset Report
            </button>
          </div>
        </div>
      </v-wait>
      <custom-reports-modal
        @reportLoaded="reportLoaded"
        renderedIn="Reporter"
        :fiType="selectedReport.fi_type"
      >
      </custom-reports-modal>
    </div>
  </b-modal>
</template>

<script>
//global
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import _ from 'lodash'
//ui components
import CustomReport from '@/modules/report_writer/components/CustomReport'
import Multiselect from 'vue-multiselect'
import CustomReportsModal from '@/modules/advanced_query/components/CustomReportsModal'
//api
import selfServeReportsAPI from '@/api/finapps/self_serve_reports'
//validations
import { required } from 'vuelidate/lib/validators'
//utilities
import deepClone from '@/utilities.js'

export default {
  name: 'IRReportWriterModal',
  props: {
    renderedIn: {
      type: String,
      default: 'Industry Reporter'
    }
  },
  components: {
    Multiselect,
    CustomReport,
    CustomReportsModal
  },
  data() {
    return {
      isValid: false,
      reportName: null,
      saveAsMode: false,
      //isKeyMetrics: false,
      allFITypeOptions: [
        { value: 'bank', text: 'Bank' },
        { value: 'credit_union', text: 'Credit Union' },
        { value: 'bhc', text: 'BHC' },
        { value: 'both', text: 'Bank & Credit Union' }
      ]
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      selectedReport: state => state.selectedReport,
      reportElements: state => state.reportElements,
      actualFIType: state => state.actualFIType,
      savedReportElements: state => state.savedReportElements
      // keyMetricsReports: state => state.keyMetricsReports
    }),
    ...mapState('Authentication', {
      userGroups: state => state.userGroups
    }),
    ...mapFields('ReportWriter', {
      reportGroupIDs: 'selectedReport.group_ids',
      reportDescription: 'selectedReport.description',
      reportCategory: 'selectedReport.category'
    }),
    selectedGroups: {
      get() {
        return this.userGroups.filter(ug => {
          return this.reportGroupIDs.includes(ug.id)
        })
      },
      set(groups) {
        this.setReportGroupIDs(groups.map(g => g.id))
      }
    },
    isEditMode() {
      return this.selectedReport.id
    },
    isUpdateMode() {
      return this.isEditMode && !this.saveAsMode
    },
    renderedOnReporterTool() {
      return this.renderedIn === 'FIN Reporter'
    },
    fiTypeOptions() {
      if (this.renderedOnReporterTool) {
        return this.allFITypeOptions
      } else {
        return this.allFITypeOptions.filter(type => type.value !== 'bhc')
      }
    },
    fiType: {
      get() {
        return this.fiTypeOptions.find(
          ft => ft.value === this.selectedReport.fi_type
        )
      },
      set(type) {
        if (this.reportElements.length) {
          this.$dialog
            .confirm(
              {
                title: 'Alert',
                body:
                  'Changing FI Type reset all your selections. Do you want to continue?'
              },
              {
                okText: 'Yes',
                cancelText: 'No'
              }
            )
            .then(() => {
              this.clearFITypeSpecificData()
              this.setReportFIType(type.value)
              this.$nextTick(() => {
                this.$refs.customReport.reloadActiveSegment()
              })
            })
            .catch(() => {})
        } else {
          this.setReportFIType(type.value)
          this.$nextTick(() => {
            this.$refs.customReport.reloadActiveSegment()
          })
        }
      }
    },
    isQueryNameUnchanged() {
      return (
        this.selectedReport.name.toLowerCase().trim() ===
        this.reportName.toLowerCase().trim()
      )
    },
    isReportNameUnchanged() {
      return (
        this.selectedReport.name.toLowerCase().trim() ===
        this.reportName.toLowerCase().trim()
      )
    },
    showEditWarning() {
      return (
        this.selectedReport.id &&
        this.reportName &&
        this.selectedReport.name.trim() !== this.reportName.trim()
      )
    },
    showWarning() {
      return (
        this.selectedReport.id &&
        this.selectedReport.fi_type !== this.actualFIType
      )
    },
    // metricReportAlreadySet() {
    //   // console.log(this.fiType.value)
    //   // console.log(this.actualFIType)
    //   // console.log(
    //   //   this.keyMetricsReports.find(r => r.fi_type == this.fiType.value)
    //   // )
    //   return this.fiType.value === 'both'
    //     ? true
    //     : this.keyMetricsReports.find(r => r.fi_type == this.fiType.value)
    //     ? true
    //     : false
    // },
    keyMetricReport() {
      return this.reportCategory === 'key_metrics'
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'setCustomReport',
      'setReportFIType',
      'clearReportData',
      'setReportElements',
      'updateCustomizedElements',
      'setReportGroupIDs',
      'setSavedReportElements',
      'clearReportDataForIndustryReporter'
    ]),
    ...mapActions('ReportWriter', ['fetchElementsInfo']),
    loadInitData() {
      this.$v.$reset()
      this.$wait.start('loadingSelectedReport')
      this.clearReportDataForIndustryReporter()

      this.saveAsMode = false
      this.reportName = this.isEditMode ? this.selectedReport.name : null

      if (this.isEditMode) {
        Promise.all([this.fetchElementsInfo(), this.fetchReportInfo()]).then(
          () => {
            this.$wait.end('loadingSelectedReport')
          }
        )
      } else {
        this.fetchElementsInfo().then(() => {
          this.$wait.end('loadingSelectedReport')
        })
      }
    },
    fetchReportInfo() {
      return selfServeReportsAPI.edit(this.selectedReport.id).then(() => {
        this.setSavedReportElements(deepClone(this.reportElements))
      })
    },
    saveReport() {
      this.isUpdateMode && this.isReportNameUnchanged
        ? this.updateReport()
        : this.createReport()
    },
    createReport() {
      this.$v.$touch()

      if (!this.$v.$error) {
        selfServeReportsAPI
          .create(
            this.reportName,
            this.reportDescription,
            this.reportGroupIDs,
            this.fiType.value
            // this.isKeyMetrics ? 'key_metrics' : 'custom_report'
          )
          .then(res => {
            if (res) {
              this.setCustomReport(res.report)
              this.setSavedReportElements(deepClone(this.reportElements))
              this.$toasted.global.action_success(
                'Report created successfully.'
              )
              this.resetModal()
              this.$bvModal.hide('irReportWriterModal')
              this.$emit('reportModalClosed')
            }
          })
      }
    },
    updateReport() {
      this.$v.$touch()

      if (!this.$v.$error) {
        selfServeReportsAPI
          .update(
            this.selectedReport.id,
            this.reportName,
            this.reportDescription,
            this.reportGroupIDs,
            this.fiType.value
            // this.isKeyMetrics ? 'key_metrics' : 'custom_report'
          )
          .then(res => {
            if (res) {
              this.setSavedReportElements(deepClone(this.reportElements))
              this.$toasted.global.action_success(
                'Report updated successfully.'
              )
              this.resetModal()
              this.$bvModal.hide('irReportWriterModal')
              this.$emit('reportModalClosed')
            }
          })
      }
    },
    closeModal() {
      if (this.hasUnsavedChanges()) {
        this.$dialog
          .confirm(
            {
              title: 'Alert',
              body:
                'Your preferences are not saved, this action may result in loss of data. Do you still want to continue?'
            },
            {
              cancelText: 'Cancel'
            }
          )
          .then(() => {
            this.resetModal()
            this.$emit('reportModalClosed')
            this.$bvModal.hide('irReportWriterModal')
          })
          .catch(() => {})
      } else {
        this.resetModal()
        this.$emit('reportModalClosed')
        this.$bvModal.hide('irReportWriterModal')
      }
    },
    clearFITypeSpecificData() {
      this.setReportElements([])
      this.updateCustomizedElements([])
    },
    hasUnsavedChanges() {
      if (this.isEditMode) {
        return !_.isEqual(this.reportElements, this.savedReportElements)
      } else {
        return this.reportElements.length
      }
    },
    reportLoaded() {
      this.saveAsMode = true
      this.reportName = this.selectedReport.name
    },
    resetReport() {
      this.$v.$reset()
      this.clearReportDataForIndustryReporter()
      let currentFiType = this.selectedReport.fi_type
      if (!this.isUpdateMode) {
        this.reportName = null
        this.clearReportData()
        this.setReportFIType(currentFiType)
      }
      this.$nextTick(() => {
        this.$refs.customReport.reloadActiveSegment()
      })
    },
    resetModal() {
      this.$v.$reset()
      this.reportName = null
      let currentFiType = this.selectedReport.fi_type
      this.clearReportData()
      this.clearReportDataForIndustryReporter()
      this.setReportFIType(currentFiType)
    },
    isGroupSelected(option) {
      return this.reportGroupIDs.includes(option.id)
    }
  },
  validations: {
    reportName: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  color: #b8b3b3;
}
.favorite__star__selected {
  color: #a7ca77;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.25rem;
  cursor: pointer;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
  -webkit-text-stroke: 0.6px grayscale($color: #686262fb);
}
</style>

<style lang="scss">
.custom-report-modal {
  @media (min-width: 768px) {
    .modal-xl {
      width: 85%;
      max-width: 1200px;
    }
  }
}
</style>
