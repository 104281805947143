var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-0"},[_c('b-card',{staticClass:"page-card",attrs:{"header-class":"page-card-with-logo page-card-header","body-class":"page-card-body"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('b-row',{staticClass:"fit-to-center"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"page-header-left"},[_c('div',{staticClass:"pr-1 header-icon"},[_c('i',{staticClass:"cuis-spreadsheet card-icon-font"})]),_c('div',[_c('h5',{staticClass:"mb-0"},[_c('b',[_vm._v("FIN Reporter")]),_vm._v(" - "),_c('i',[_vm._v("Select Report")])])])])]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"page-header-right"},[_c('header-logo',{attrs:{"logoType":"fi","logoUrl":_vm.s3LogoUrl,"logoId":_vm.selectedInstitution.id}}),_c('div',[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.selectedInstitution.name))])])],1)])],1)],1),_c('div',[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('v-wait',{attrs:{"for":"reportsLoading"}},[_c('template',{slot:"waiting"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":10}})],1)],1),_c('b-card-group',{attrs:{"deck":""}},[_c('b-card',{staticClass:"std-border reports-card",attrs:{"body-class":"p-1","header-class":"pl-3"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h6',{staticClass:"mb-0"},[_c('b',[_vm._v("My Custom Reports")])])]),_c('v-wait',{attrs:{"for":"customReportsLoading"}},[_c('template',{slot:"waiting"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":12}})],1)],1),_c('b-list-group',{staticClass:"selectable-list-group"},[(this.customReports.length === 0)?_c('b-list-group-item',[_c('i',[_vm._v("No Reports found")])]):_vm._l((_vm.orderedCustomReports),function(report,index){return _c('b-list-group-item',{key:index,class:[
                        { selected: _vm.isReportSelected(report, 'custom') },
                        'p-2 '
                      ],on:{"click":function($event){return _vm.selectReport(report, 'custom')}}},[_c('span',{attrs:{"id":("cr-" + (report.id))}},[_vm._v(" "+_vm._s(report.name)+" "),(_vm.isFinApiEnterpriseUser)?_c('span',{staticClass:"text-muted"},[_vm._v("["+_vm._s(report.id)+"] ")]):_vm._e(),(report.group_ids && report.group_ids.length)?_c('span',{staticClass:"text-muted"},[_c('b-badge',{staticClass:"px-1",attrs:{"pill":"","variant":"secondary"}},[_vm._v("Shared ")])],1):_vm._e()]),(report.category === 'key_metrics')?_c('span',{staticClass:"p-0 pl-1 pb-1  favorite__star__selected"},[_c('b',[_vm._v(" ★")])]):_vm._e(),_c('span',{staticClass:"manage--report-group"},[_c('button',{staticClass:"pt-0 float-right btn btn-secondary btn-sm mx-1",attrs:{"type":"submit"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCustomReport(report)}}},[_vm._v(" Delete ")]),_c('button',{staticClass:"pt-0 float-right btn btn-primary btn-sm mx-1",attrs:{"type":"submit"},on:{"click":function($event){$event.stopPropagation();return _vm.editCustomReport(report)}}},[_vm._v(" Edit ")])]),(report.description)?_c('b-popover',{attrs:{"target":("cr-" + (report.id)),"triggers":"hover","placement":"left"}},[_vm._v(" "+_vm._s(report.description)+" ")]):_vm._e()],1)})],2)],2)],1),_c('b-card',{staticClass:"std-border reports-card",attrs:{"body-class":"p-1","header-class":"pl-3"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h6',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(_vm.navigatorTitleText))])])]),_c('b-list-group',{staticClass:"selectable-list-group"},[(this.navigatorReports.length === 0)?_c('b-list-group-item',[_c('i',[_vm._v("No Reports found")])]):_vm._l((_vm.navigatorReports),function(report,index){return _c('b-list-group-item',{key:index,class:[
                      { selected: report.id === _vm.metric_args.reportTypeId },
                      'p-2'
                    ],on:{"click":function($event){return _vm.selectReport(report, 'finavigator')}}},[_vm._v(" "+_vm._s(report.name)+" ")])})],2)],1)],1)],2)],1)],1)],1),_c('b-row',{staticClass:"text-center pt-1"},[_c('b-col',{staticClass:"mt-3",attrs:{"md":"12","lg":"10"}},[_c('b-button',{staticClass:"fw-btn mx-1",attrs:{"size":"","variant":"primary"},on:{"click":_vm.createCustomReport}},[_vm._v("Create Report")]),_c('router-link',{staticClass:"btn btn-primary fw-btn",attrs:{"tag":"button","to":{
            path: 'metrics',
            query: {
              id: _vm.metric_args.reportId,
              report_id: _vm.metric_args.reportTypeId,
              report_type: _vm.metric_args.reportType
            }
          },"disabled":!_vm.enableContinue},nativeOn:{"click":function($event){return _vm.trackReportView($event)}}},[_vm._v(" View Report ")])],1),_c('b-col',{staticClass:"text-right mt-3",attrs:{"md":"12","lg":"2"}},[_c('span',[_c('i',[_vm._v(" Default View: ")]),_c('b-dropdown',{staticClass:"fw-btn",attrs:{"id":"ddown-offset","text":_vm.selectedView}},[_c('b-dropdown-item',{on:{"click":_vm.updateMultiView}},[_vm._v(_vm._s(_vm.isTechnographicReport ? 'Multi-Service View' : 'Multi-Metric View'))]),_c('b-dropdown-item',{on:{"click":_vm.updateSingleView}},[_vm._v(_vm._s(_vm.isTechnographicReport ? 'Single-Service View' : 'Single-Metric View'))]),(_vm.isCustomReport && !_vm.isTechnographicReport)?_c('b-dropdown-item',{on:{"click":_vm.updatePeriodView}},[_vm._v("Period View")]):_vm._e()],1)],1)])],1),(_vm.showReportMetrics())?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{staticClass:"std-border metrics-card mt-4",attrs:{"body-class":"p-1","header-class":"pl-3"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h6',{staticClass:"mb-0"},[_c('b',[_vm._v("Report Metrics")])])]),_c('v-wait',{attrs:{"for":"metricsLoading"}},[_c('template',{slot:"waiting"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":15}})],1)],1),_c('b-list-group',{staticClass:"selectable-list-group"},_vm._l((_vm.metric_args.metricsList),function(report,index){return _c('b-list-group-item',{key:index,staticClass:"p-2"},[_vm._v(" "+_vm._s(report.name)+" ")])}),1)],2)],1)],1)],1):_vm._e()],1),_c('report-writer-modal',{attrs:{"renderedIn":"FIN Reporter"},on:{"reportModalClosed":_vm.reportClosed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }